import { RyuBreak, RyuHeading } from '@ramp/ryu'
import React, { Fragment, FunctionComponent, ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'

type Props = {
  title?: string
  children: ReactNode
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const TitledContent: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title ? `${title} — Ramp Engineering` : 'Ramp Engineering'}</title>
      </Helmet>

      <RyuBreak />

      <ScrollToTop />
      <RyuHeading>
        <span style={{ lineHeight: 1.3 }}>{title ?? 'Ramp Engineering'}</span>
      </RyuHeading>

      <RyuBreak size='xl' />

      {children}
    </Fragment>
  )
}
