import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'

import { BlogPostPreviewCard } from '~/src/components/BlogPostPreviewCard'
import { TitledContent } from '~/src/components/TitledContent'
import { blogPosts } from '~/src/utils/blog'
import { BLOG_DESCRIPTION, BLOG_TITLE, ORIGIN, RAMP_TWITTER } from '~/src/utils/metadata'

type Props = {}

export const BlogHomeRoute: FunctionComponent<Props> = () => {
  return (
    <TitledContent title='Blog posts'>
      <Helmet>
        <link rel='canonical' href={ORIGIN} />

        <meta
          name='description'
          property='og:description'
          itemProp='description'
          content={BLOG_DESCRIPTION}
        />

        <meta property='og:url' itemProp='url' content={ORIGIN} />

        <meta name='twitter:title' itemProp='name' property='og:title' content={BLOG_TITLE} />
        <meta name='twitter:description' content={BLOG_DESCRIPTION} />
        <meta name='twitter:site' content={RAMP_TWITTER} />
        <meta name='twitter:creator' content={RAMP_TWITTER} />
      </Helmet>

      {blogPosts.map((blogPost) => (
        <BlogPostPreviewCard blogPost={blogPost} key={blogPost.path} />
      ))}
    </TitledContent>
  )
}
