// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/lausanne/lausanne-300/Lausanne-300.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/lausanne/lausanne-300/Lausanne-300.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/lausanne/lausanne-400/Lausanne-400.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "./fonts/lausanne/lausanne-400/Lausanne-400.woff";
import ___CSS_LOADER_URL_IMPORT_4___ from "./fonts/burgess/burgess-400/Burgess-400.woff2";
import ___CSS_LOADER_URL_IMPORT_5___ from "./fonts/burgess/burgess-400/Burgess-400.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Lausanne';\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Lausanne';\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Burgess';\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://./styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB;0DACuE;AACzE;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB;0DACuE;AACzE;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB;0DACoE;AACtE","sourcesContent":["@font-face {\n  font-family: 'Lausanne';\n  font-weight: 300;\n  font-style: normal;\n  font-display: swap;\n  src: url('./fonts/lausanne/lausanne-300/Lausanne-300.woff2') format('woff2'),\n    url('./fonts/lausanne/lausanne-300/Lausanne-300.woff') format('woff');\n}\n\n@font-face {\n  font-family: 'Lausanne';\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n  src: url('./fonts/lausanne/lausanne-400/Lausanne-400.woff2') format('woff2'),\n    url('./fonts/lausanne/lausanne-400/Lausanne-400.woff') format('woff');\n}\n\n@font-face {\n  font-family: 'Burgess';\n  font-weight: 400;\n  font-style: normal;\n  font-display: swap;\n  src: url('./fonts/burgess/burgess-400/Burgess-400.woff2') format('woff2'),\n    url('./fonts/burgess/burgess-400/Burgess-400.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
