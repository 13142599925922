
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage } from '@ramp/ryu'
import chart_one from './chart-one.png'
import chart_two from './chart-two.png'
export const meta = {
  date: '2023-09-30T17:00:00.000Z',
  title: 'Picking Low Hanging Fruit',
  description: 'With the passage of time, small inefficiencies can add up to big bottlenecks',
  slug: 'faster-python-tests',
  author: {
    name: 'Rodda John, Luke Zeller, and Rahul Sengottuvelu'
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In July of this year, our Python monolith's test suite took 45 minutes to complete.  This slowed down developers across the company and resulted in larger backlogs of unmerged changes.`}</p>
    <p>{`After a week of optimization work, the test suite runs in under 5 minutes.`}</p>
    <h2>{`Why test times matter`}</h2>
    <p>{`Two years ago, Max Lahey `}<a parentName="p" {...{
        "href": "https://engineering.ramp.com/faster-python-tests"
      }}>{`posited that tests aid in uncovering implicit dependencies`}</a>{` (especially important in growing organizations and codebases) and that slow tests hurt engineering productivity and observability.`}</p>
    <p>{`The harm to developer efficiency is acute.  A builder’s most valuable resource is their context and focus - slow tests eliminate the ability to use either efficiently.  This problem compounds when more bugs are introduced to production as a result of skipped tests and then slow tests are the bottleneck to resolving issues and validating potential fixes.  Finally, as the test suite slows down, the number of pending PRs increases.`}</p>
    <p>{`As the merge queue depth increases so too does the amount of context a developer must keep loaded in their short term memory  - as they must be ready for any number of change sets to behave strangely once they finally appear in production. Engineers would often block off half a day just to babysit the merge queue while it slowly drained. `}</p>
    <h2>{`Always work on the highest leverage things`}</h2>
    <blockquote>
      <p parentName="blockquote">{`“Rule 2. Measure. Don't tune for speed until you've measured, and even then don't unless one part of the code overwhelms the rest.” - Rob Pike`}</p>
    </blockquote>
    <p>{`When we began, we knew the total execution time was 45 minutes and that about 38 of those minutes were spent on the `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` invocation.  At this point, we knew that slow tests were affecting engineering velocity company wide, that the vast majority of the slowness was attributable to a `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` call, and that it had been two years since a deep-dive.`}</p>
    <p>{`We needed to find the bottlenecks in our `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` runs.  We observed the resource usage of a machine running the test suite and discovered it was CPU bound.  We increased the speed of the CPU and the tests ran ~50% faster.  The single largest increase in the speed of tests took the least time to figure out and we did it first.  Try the obvious improvements first!`}</p>
    <p>{`After increasing CPU speed, we toyed with increasing thread count; however, increasing parallelization did not immediately result in a speedup.  After continued profiling, we observed our test DB buckling under the increased load.  After both increasing the number of DBs and tuning Postgres flags (e.g. `}<a parentName="p" {...{
        "href": "https://www.postgresql.org/docs/current/runtime-config-connection.html#RUNTIME-CONFIG-CONNECTION-SETTINGS"
      }}>{`connection settings`}</a>{`), we saw more minutes of time saving `}{`[1]`}{`.`}</p>
    <h2>{`Seek asymmetric outcomes`}</h2>
    <p>{`Two days in, we had achieved a ~70% speedup of our test suite (a ~30 minute saving).  Through profiling and optimizing, we had accumulated a list of potential future opportunities for more time savings.  Next we needed to validate and measure each potential opportunity as quickly as possible.  To do this we split up and each engineer focused on the area for which they had the most context.`}</p>
    <p>{`Three examples:`}</p>
    <p>{`Downloading the last-built docker image from the registry took between 1 and 2 minutes per build on the old AWS-hosted test runners. Because the new runners we experimented on were self-hosted, local docker caching should eliminate this overhead.  Implementing this improvement shaved over a minute off the total time — a nontrivial gain given total duration was around fifteen minutes.`}</p>
    <p>{`When running parallelized tests in `}<inlineCode parentName="p">{`pytest-xdist`}</inlineCode>{`, we noticed that different workers finished at different times. This meant the algorithm assigning tests to workers was inefficient and operating off of incomplete information.  We were able to quickly write a more effective packing algorithm (courtesy of ChatGPT) on a fork of `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` as a proof of concept.  This ended up saving 2-3 minutes.`}</p>
    <p>{`While profiling subsequent test runs, we noticed high disk usage - likely due to our recent introduction of multiple databases.  From the initial decision to parallelize with multiple databases we were concerned I/O could be a bottleneck, so were eager to attempt optimizations on this front.  We tried mounting everything in RAM, but did not see any performance gains.  We moved on and later realized our intuitions were wrong (for reasons native to Postgres transaction handling); nonetheless implementing the shift to RAM was trivial and had significant potential upside, so we felt justified that trying this approach was the correct tradeoff.`}</p>
    <p>{`These experiments all had the potential of asymmetric upside as they provided opportunities to test hypotheses quickly - allowing low lift iterations to potentially yield valuable results.  The ideas originated from spending time deeply understanding the test dependencies (how Postgres handles database connections), infrastructure (hardware limitations), and tooling (the internals of `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` and GitHub action level caching).`}</p>
    <h2>{`Law of Diminishing Returns`}</h2>
    <p>{`After a week of work, new improvements were yielding significantly smaller gains. Towards the end of our week focused on this, a few hours of tuning only shaved a few seconds (< 5%) off the new test suite run time, whereas at the start of the week we managed to save 20 minutes (~50% of the then current run time) with the same allocation of tuning time.  This was our clue to stop: we were no longer yielding high returns on our time investment and further focus would run the risk of solely enjoying the comfort of a project we now had context on.`}</p>
    <p>{`By graphing the time savings per change made the diminishing returns to invested effort are immediately evident:`}</p>
    <RyuImage src={chart_one} mdxType="RyuImage" />
    <p>{`Grouping by day, the curve is even clearer:`}</p>
    <RyuImage src={chart_two} mdxType="RyuImage" />
    <p>{`In our experience, all projects look like this.  Notably, this does not devalue projects with high upfront cost (e.g. `}<a parentName="p" {...{
        "href": "https://engineering.ramp.com/payments-platform"
      }}>{`building a payments platform to move money`}</a>{`).  At Ramp the value of such a platform is so large once it’s examined on a longer time scale that some initial patience to set the right foundation is obviously worthwhile.  However, even when building platforms, engineers should identify the potential for asymmetric outcomes at every stage.`}</p>
    <p>{`Every project should end with a backlog of marginal, long-tail improvements.  Solely executing on a single project effectively pales in comparison to the impact of regularly considering the primary optimization function (e.g. customer value over unit time) and then identifying the highest leverage next steps through that lens.`}</p>
    <h2>{`Give engineers tools to experiment`}</h2>
    <p>{`In partnership with Ramp’s world-class Applied AI team, accessing servers with significantly faster clock speed, more CPU cores, and more RAM to experiment with took us about ten minutes.`}</p>
    <p>{`These machines were provisioned ad hoc and not production ready (e.g. no Terraform or GitHub runner).  However, productionizing a machine for experimentation would have been a premature optimization and an incredibly low leverage way to spend time.`}</p>
    <p>{`Engineers should be able to experiment without hindrance so long as they operate within minimal, pre-defined guardrails.`}</p>
    <p>{`When finally productionizing the system, we worked closely with a number of engineers more familiar with our production CI / CD deployment who were able to productionize what we had built.`}</p>
    <p>{`This applies to more than infrastructure: e.g. put up PRs to test what refactors might look like or try to `}<inlineCode parentName="p">{`pip install`}</inlineCode>{` a PDF library locally and profile PDF creation.  Aim to build and validate new ideas very quickly and productionize them later.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Every engineer should seek to understand why the problem they’re solving is important and the highest leverage use of their time and they should be able to relate the problem to a top level optimization function.  In our case, slow tests were inhibiting engineers’ ability to quickly and confidently deliver customer value.  We believed that working on this was the highest leverage use of our time and context when we began.`}</p>
    <p>{`First, we built context on the problem - learning about CPU usage on test machines, GitHub actions, docker caching, Postgres optimizations, and reading the `}<inlineCode parentName="p">{`pytest`}</inlineCode>{` source code.  When we understood enough to identify potential asymmetric outcomes, we moved to action.`}</p>
    <p>{`Second, we validated whether our initial hypotheses were correct by building the minimal viable example to measure the result.  Many were validated and some were disproven.`}</p>
    <p>{`Finally, we constantly re-evaluated our approach as we learned more and we weighed continuing to work on tests given potential upside, opportunity cost, and context cost.`}</p>
    <p>{`Organizations should empower engineers with the tools they need to effectively impact business goals and trust them to optimize appropriately - as Ramp does.`}</p>
    <p>{`There were always too many things to try and not too few - this is common, teams always have more ideas than time.  While discipline and effort can increase throughput, effective prioritization increases bandwidth more than anything else.`}</p>
    <p>{`The genesis of the project was leverage: we believed and could prove that longer test times harmed productivity of the engineering organization.  A speedup of the test suite and its accompanying increase in engineer productivity is multiplied over the entire organization, meaning even marginal improvements yield outsized results.`}</p>
    <p>{`Each improvement’s impact was impossible to predict before trying them, for example we had no idea higher parallelism would result in massive speedups.  However, we did know the cost of attempting it was low, and the potential upside was high - the crux of asymmetric outcomes.  While experience can help tune expectations, uncertainty remains inevitable.  Test optimization serves as a good case study for this general principle as the impact of every change we made was both immediate and precisely quantifiable.  While returns on investment are usually less immediate and quantifiable, they remain quite real.`}</p>
    <p>{`Ramp has maintained a culture of velocity from the beginning, through the present (over 150 engineers, 700 employees, and rapidly growing revenue).  We do not maintain this velocity through faster typing, talking, or walking, but we do through leverage.  We build tools and systems across the organization to empower everyone to do the highest leverage work they can.  When we commit to projects, we execute swiftly and with a consistent eye for the impact of actions on the goals of the project and the business.  `}</p>
    <p>{`We’re just getting started; the problems continue to grow, as do the opportunities.  We’d love to have you `}<a parentName="p" {...{
        "href": "https://ramp.com/careers"
      }}>{`join us`}</a>{` on this journey.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/rodda-john/"
      }}>{`Rodda John`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/luke-zeller/"
      }}>{`Luke Zeller`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/rahul-gs/"
      }}>{`Rahul Sengottuvelu`}</a><br /></p>
    <hr />
[1]: The DB exhibited lock contention when more than 20 workers accessed the same DB.  To solve this, we spun up more DBs, experimented on the optimal ratio, and discovered ~12 workers per DB was optimal.  Increasing the count of DBs beyond this resulted in slight regressions, likely because DBs themselves consume resources then unavailable to main test threads.
    <hr />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;