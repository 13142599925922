import { RyuBreak, RyuHeading, RyuPad, RyuText } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const BlogCTARoot = styled.div`
  background-color: ${({ theme }) => theme.ryuColors.lighterShade};
  border-radius: 4px;

  a {
    text-decoration: none;
  }
`

export const BlogPostCTA: FunctionComponent = () => {
  return (
    <BlogCTARoot>
      <a href='https://ramp.com/careers' target='_blank' rel='noopener noreferrer'>
        <RyuPad size='xl' sizeVertical='xl'>
          <RyuHeading size='s'>Like what you see? Join us →</RyuHeading>
          <RyuBreak size='m' />
          <RyuText color='hushed'>
            Come build the future of finance automation that increases the lifespan of businesses.
          </RyuText>
        </RyuPad>
      </a>
    </BlogCTARoot>
  )
}
