
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuTable } from '@ramp/ryu'
import model_architecture from './model_architecture.png'
import metaflow_ui from './metaflow_ui.png'
import metaflow_process from './metaflow_process.png'
import metaflow_operator from './metaflow_operator.png'
export const meta = {
  date: '2023-09-15T17:00:00.000Z',
  title: 'How Ramp Accelerated Machine Learning Development to Simplify Finance',
  description: 'A walkthrough of why and how Ramp uses Metaflow for machine learning engineering',
  author: {
    name: 'Peyton McCullough',
    website: 'https://peytonmccullough.com',
    twitter: '',
    position: 'Engineering',
    bio: ''
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Simplifying Finance with Machine Learning at Ramp`}</h2>
    <p>{`At Ramp, we work hard to simplify finance and help thousands of businesses control spend, save time, and automate busy work. As one of our core competencies, machine learning is invaluable across many aspects of our value chain, and we apply ML across a number of different domains:`}</p>
    <ul>
      <li parentName="ul">{`Credit risk, such as predicting the probability that a Ramp customer will become delinquent.`}</li>
      <li parentName="ul">{`Fraud, such as determining whether a card transaction is fraudulent.`}</li>
      <li parentName="ul">{`Growth, such as predicting the probability that a potential lead will convert into a customer.`}</li>
      <li parentName="ul">{`Product, such as suggesting an accounting code for a particular transaction and `}<a parentName="li" {...{
          "href": "https://ramp.com/intelligence"
        }}>{`Ramp Intelligence`}</a>{`, Ramp’s new suite of AI products.`}</li>
    </ul>
    <p>{`One perennial challenge with machine learning is the speed of moving models from prototype to production, and then iterating. Metaflow helped us to shorten this feedback cycle and increase our velocity.`}</p>
    <h2>{`Long Feedback Loops, and Excessive Friction`}</h2>
    <RyuImage src={model_architecture} mdxType="RyuImage" />
    <p>{`One of our first machine learning models was a “riskiness” model. Our goal was simple: every day, predict the level of risk associated with tens of thousands of Ramp customers. This ​​is the main credit model we use in our risk management process and is business-critical. It’s also a fairly simple model, built with scikit-learn and xgboost. It has about 20 features. We used an off-the-shelf vendor solution because it was available, and we immediately encountered issues that slowed us down:`}</p>
    <ul>
      <li parentName="ul">{`We couldn’t define and execute ML pipelines locally. We had to manually push our pipeline code to the vendor before we could run it.`}</li>
      <li parentName="ul">{`Jobs took upwards of an hour to run, even for very small datasets.`}</li>
      <li parentName="ul">{`Jobs were flaky, and when things went wrong, we had limited visibility into the causes. Sometimes jobs would fail seemingly for no reason, and logging was poor.`}</li>
      <li parentName="ul">{`The platform didn’t work well with Docker containers. We’d get unintelligible errors when using Docker and there were features in the platform that weren’t available for containerized workloads.`}</li>
    </ul>
    <p>{`Our setup also required a lot of platform involvement, including tuning resources, granting permissions, and reviewing PRs, which didn’t really allow for workflows and resulted in a sub-optimal developer experience. It also meant that there wasn’t great visibility into what was running, and development took a long time. The riskiness model took months to build!`}</p>
    <p>{`All of these pain points slowed down our velocity, frustrating both data scientists and their stakeholders. Long feedback loops and excessive friction are particularly painful at early stages where iteration is key. Ramp is well-known for our product velocity, and we set an extremely high bar for developer experience and fast feedback loops. Data science and ML cannot be exceptions to this and, in 2022, we couldn’t get machine learning models into production as fast as we would have liked.`}</p>
    <p>{`It was clear that we needed a different solution.`}</p>
    <h2>{`Choosing Metaflow`}</h2>
    <p><a parentName="p" {...{
        "href": "https://docs.metaflow.org"
      }}>{`Metaflow`}</a>{`, in conjunction with developer experience improvements, solved our pain points. After adopting Metaflow, we were able to ship eight additional models in just ten months, whereas before it took many more months to launch a single model. We’re still early in our journey, and these are just the exciting first steps. Within the next six months, we plan to train and launch five to ten additional models, supported by at least that many production flows.`}</p>
    <RyuTable columns={[{
      label: 'Problem Area',
      render: area => area.area,
      align: 'center',
      width: 3
    }, {
      label: 'Before',
      render: area => area.before,
      align: 'left',
      width: 6
    }, {
      label: 'After',
      render: area => area.after,
      align: 'left',
      width: 6
    }]} getRowKey={row => row.area} getRowHeight={row => 6} rows={[{
      area: "Deployment",
      before: <ul><li>Data scientists manually deployed code on the command line</li></ul>,
      after: <ul><li>Flows are automatically deployed</li></ul>
    }, {
      area: "Dependency Management",
      before: <ul><li>Container support was limited, so data scientists often ran into problems</li></ul>,
      after: <ul><li>Dependencies are Dockerized and standardized</li></ul>
    }, {
      area: "Resource Requirements",
      before: <ul><li>All of a job’s steps typically ran on the same resources</li><li>Tuning resources often required Data Platform involvement</li></ul>,
      after: <ul><li>Resources can be configured per step</li><li>Data scientists can use whatever resources they need, up to a large limit</li></ul>
    }, {
      area: "Debugging",
      before: <ul><li>Individual steps were difficult to retry</li><li>Logs were difficult to find</li></ul>,
      after: <ul><li>Individual steps can be retried</li><li>Logs are surfaced in UI</li></ul>
    }, {
      area: "Sharing Results",
      before: <ul><li>Data scientists could share notebooks, but pointing to one specific run was challenging</li></ul>,
      after: <ul><li>Data scientists can link to individual runs</li><li>Results can be shared in Metaflow cards</li></ul>
    }]} mdxType="RyuTable" />
    <p>{`There are lots of ML platform choices out there, and many of them are great! It’s also important to note that some are more popular than others as we wanted to choose than that we knew would be well-supported by a vibrant community and team. In making our choice, we first narrowed down our choices to popular tools because we wanted something well-supported by a vibrant community and team. Then, we optimized for simplicity and velocity:`}</p>
    <p>{`For simplicity, we focused on both the infrastructure and end-user code. On the infrastructure side, we wanted to leverage AWS-managed services where possible. On the end-user side, we wanted our data scientists and machine learning engineers to be able to get up and running quickly.`}</p>
    <p>{`Velocity naturally follows from simplicity. We wanted to be able to stand up infrastructure and basic models quickly.`}</p>
    <p>{`Enter Metaflow. Metaflow is an open-source ML framework for training and managing ML models and building ML systems. It allows data scientists and MLEs to access all layers of the full stack of machine learning, from data and compute to versioning and deployment, while focusing on building models in Python. Metaflow also integrates with pre-existing workflow orchestrators, like `}<a parentName="p" {...{
        "href": "https://medium.com/apache-airflow/mind-the-gap-seamless-data-and-ml-pipelines-with-airflow-and-metaflow-7e40213dd719"
      }}>{`Airflow`}</a>{`, `}<a parentName="p" {...{
        "href": "https://blog.argoproj.io/human-centric-data-science-on-kubernetes-with-metaflow-7f60aad34cba"
      }}>{`Argo Workflows`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://netflixtechblog.com/unbundling-data-science-workflows-with-metaflow-and-aws-step-functions-d454780c6280"
      }}>{`AWS Step Functions`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://docs.metaflow.org/scaling/remote-tasks/introduction"
      }}>{`compute infrastructure`}</a>{` like Kubernetes or AWS Batch. With Metaflow, data scientists can:`}</p>
    <ul>
      <li parentName="ul">{`Define their ML pipelines purely in Python as a Metaflow Flow.`}</li>
      <li parentName="ul">{`Run those pipelines locally with a simple `}<inlineCode parentName="li">{`python run_flow.py`}</inlineCode>{` command.`}</li>
      <li parentName="ul">{`Run those same pipelines in the cloud by adding a `}<inlineCode parentName="li">{`--with batch`}</inlineCode>{` flag to that same command.`}</li>
      <li parentName="ul">{`Push the Metaflow code to production, and trigger that same Flow from an Airflow DAG.`}</li>
      <li parentName="ul">{`Visualize results using Metaflow “cards” visible from the UI.`}</li>
      <li parentName="ul">{`Easily share results and cards via links.`}</li>
    </ul>
    <p>{`This is all great! As long as the infrastructure is reliable, this significantly tightens feedback loops and removes undue friction on the path from prototype to production.`}</p>
    <p>{`You may be wondering “Why not just Airflow for everything?” Indeed, we use Airflow extensively at Ramp. Airflow is a battle-tested, Python-based orchestrator and the de-factor tool in data engineering! While this is true, it isn’t always the best tool for machine learning engineering:`}</p>
    <ul>
      <li parentName="ul">{`Airflow is meant to be used as an orchestrator for compute workloads, rather than the workloads themselves. Airflow isn’t meant to process the data. It instead, say, triggers a Spark job that processes the data. It’s the same with machine learning.`}</li>
      <li parentName="ul">{`Since Airflow is a general-purpose orchestrator, it’s used for a wide variety of applications that have different dependencies. ML libraries often have extremely quirky dependencies that don’t play nicely with other common dependencies.`}</li>
      <li parentName="ul">{`With Airflow, we can’t run a pipeline locally and then immediately run it in the cloud with the flip of a switch. We have to merge and deploy it to a production cloud environment first.`}</li>
      <li parentName="ul">{`Airflow doesn't come with "ML batteries included," so a considerable amount of time and additional libraries are needed to build a complete system. In contrast, Metaflow includes features for, for example, inspecting and analyzing results in a notebook, creating model cards, and accessing large amounts of data quickly out of the box, making it easier for even an inexperienced data scientist to build systems independently.`}</li>
    </ul>
    <p>{`Metaflow still includes a handy UI where data scientists can examine task progress:`}</p>
    <RyuImage src={metaflow_ui} mdxType="RyuImage" />
    <p>{`Overall, Metaflow offers a simple workflow for development models.`}</p>
    <RyuImage src={metaflow_process} mdxType="RyuImage" />
    <h2>{`The Technical Details of Our Setup`}</h2>
    <p>{`At Ramp, we use AWS extensively, and since Metaflow can be deployed on AWS-managed services, we decided to start there.`}</p>
    <p>{`At its core, Metaflow can use AWS Batch to schedule jobs and run them on AWS-managed ECS clusters. Batch provides job queue functionality that can keep track of jobs. For each job queue, you can attach multiple compute environments. These compute environments can run on Fargate or EC2, and you can adjust the resources available and the scaling strategies.`}</p>
    <p>{`Since we’re small, we created a single job queue to start with. At first, we attached a Fargate compute environment, since we’re very familiar with Fargate, and it’s fairly simple to get started with. However, we encountered several problems with this. First, Fargate startup times are quite long. Users who want to submit more interactive jobs regularly had to wait several minutes for jobs to start. Second, Fargate only supports certain combinations of CPU and memory. This is sometimes a headache for software engineers, and it’s definitely a headache for data scientists. Writing a flow might require referencing the AWS documentation, which isn’t ideal. Third, Fargate doesn’t support GPUs. This isn’t a problem for many models, but for more sophisticated models, this is an annoying limitation. So, we ended up quickly moving to EC2 compute environments.`}</p>
    <p>{`As we grow, we anticipate having to tweak our AWS Batch setup. We may want to optimize the instance types we use, particularly for GPUs. We may also want to create multiple queues in order to separate production and ad hoc workflows, or to accommodate different priorities. We’re still early in our journey here.`}</p>
    <p>{`We use Terraform to define our infrastructure, so we’re able to describe all of Metaflow’s infrastructure as code.`}</p>
    <p>{`When running flows locally, Metaflow orchestrates steps from your laptop, even if they’re remote. This is fine for short-running, interactive jobs. For longer-running or production jobs, we chose Metaflow’s Step Functions integration. Step Function overlaps with Airflow in some ways (indeed, we very briefly used Step Functions for general orchestration). It handles transitioning between steps and retrying failures, and it can be triggered on a schedule. However, we only use it for managing flow execution. In order to handle scheduling or triggering, we trigger Step Function executions from Airflow.`}</p>
    <p>{`At first, we used the Amazon provider to integrate between Step Functions and Airflow—namely, `}<a parentName="p" {...{
        "href": "https://registry.astronomer.io/providers/amazon/versions/latest/modules/stepfunctionstartexecutionoperator"
      }}>{`StepFunctionStartExecutionOperator`}</a>{` and `}<a parentName="p" {...{
        "href": "https://registry.astronomer.io/providers/apache-airflow-providers-amazon/versions/8.6.0/modules/StepFunctionExecutionSensor"
      }}>{`StepFunctionExecutionSensor`}</a>{`. However, this wasn’t pleasant. Data scientists had to create two boilerplate tasks to trigger execution, using Step Function state machine ARNs that make little sense in the context of Metaflow and data science. Then, if something went wrong, data scientists had to track down logs either in the AWS Console, the Metaflow UI, or both. This wasn’t ideal.`}</p>
    <p>{`To remedy this, we created a MetaflowOperator. The operator handles triggering a flow and waiting for success or failure. Importantly, it also creates links to relevant logs.`}</p>
    <RyuImage src={metaflow_operator} mdxType="RyuImage" />
    <p>{`More recently, Metaflow includes the `}<a parentName="p" {...{
        "href": "https://medium.com/apache-airflow/mind-the-gap-seamless-data-and-ml-pipelines-with-airflow-and-metaflow-7e40213dd719"
      }}>{`capability to generate Airflow DAGs from Metaflow Flows`}</a>{`. We haven’t used this functionality yet but look forward to digging deeper.`}</p>
    <p>{`All of our production flows are contained in a single repository, sharing the same set of dependencies. This can be limiting, but it allows us to optimize for ease of maintenance and support. Whenever a data scientist creates a new flow or updates an existing one, we automatically create the associated Step Function state machine, allowing data scientists to then trigger it at will, either manually or from Airflow.`}</p>
    <h2>{`Where We are Today`}</h2>
    <p>{`Currently, we have more than 6000 Flow runs. These involve both scheduled tasks that happen every day and many on-the-spot tasks. Among these, a few data scientists are regularly running and adjusting processes, which results in multiple runs in a row. Out of these runs, a number are categorized as "production," meaning they run on a specific schedule triggered by factors like new data arriving in a database or a daily requirement. Apart from these, we have many more runs that are done for research or to solve ad hoc problems. For instance, I often run flows to benchmark models, and our data scientists are actively engaged in a wide range of projects.`}</p>
    <p>{`Several of these models are essential for the business. For example, the riskiness model, along with a handful of other risk models, feeds into credit limits. If these models malfunction, fixing them is a very high priority. Other models are ongoing experiments designed to test specific inferences in a shadow mode. So, we cover a wide range of production scenarios. Our data scientists and MLEs can easily and swiftly compose flows. These are all housed in a single repository and are easily accessible.`}</p>
    <p>{`Because of the simplicity of Metaflow, data scientists are able to largely self-service on Metaflow. While many data platform teams aim for self-service capabilities, it's rare to see stakeholders actually use and appreciate these features. New users are typically able to onboard themselves within their first few days at Ramp. To help with this, we created a brief walkthrough that new users can follow along with to create and deploy an example Flow. Our team hasn’t had to spend much time helping users with Metaflow, nor have we had to spend much time debugging infrastructure issues. Things mostly just work, allowing data platform engineers to tackle other problems.`}</p>
    <p>{`If this work interests you, we’ll be `}<a parentName="p" {...{
        "href": "https://airflowsummit.org/sessions/2023/better-airflow-with-metaflow-a-modern-human-centric-ml-infrastructure-stack/"
      }}>{`speaking about it`}</a>{` on September 20 in more detail at the Airflow Summit with our friends from `}<a parentName="p" {...{
        "href": "https://outerbounds.com/"
      }}>{`Outerbounds`}</a>{`. You can `}<a parentName="p" {...{
        "href": "https://airflowsummit.org/tickets/"
      }}>{`sign up here`}</a>{`!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;