import { withRyuErrorBoundary } from '@ramp/ryu'
import { orderBy } from 'lodash'

import { BlogPost } from '~/src/types'

export const blogPosts: BlogPost[] = importAllBlogPosts()

function importAllBlogPosts() {
  // @ts-ignore trusty code is to be trusted.
  const requireContext = require.context('../../articles')
  const modulePaths = requireContext.keys().filter((modulePath: string) => modulePath.endsWith('.mdx'))

  const unsortedBlogPosts = modulePaths.map((modulePath: string): BlogPost => {
    const mdx = requireContext(modulePath)
    const MdxContent = withRyuErrorBoundary(mdx.default)

    const { date: dateRaw, ...rest } = mdx.meta

    const pathFirstSep = modulePath.indexOf('/')
    const pathSecondSep = modulePath.indexOf('/', pathFirstSep + 1)
    const path = modulePath.slice(pathFirstSep, pathSecondSep)
    const date = new Date(dateRaw)

    return {
      ...rest,
      path,
      date,
      MdxContent,
    }
  })

  const blogPosts = orderBy(unsortedBlogPosts, ['date'], ['desc'])

  return blogPosts
}
