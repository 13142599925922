import { RyuBreak, RyuLink, RyuPad, RyuText, withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { RegularPageContainer } from '~/src/components/RegularPageContainer'

const BlogFooterRoot = styled.div`
  background-color: ${({ theme }) => theme.ryuColors.lightestShade};
  padding: 2rem 0;
`

export const BlogFooter: FunctionComponent = withRyuErrorBoundary(() => {
  return (
    <BlogFooterRoot>
      <RegularPageContainer>
        <RyuPad size='l' sizeVertical='xl'>
          <RyuText color='hushed' size='s'>
            © {new Date().getFullYear()} Ramp Business Corporation. “Ramp,” "Ramp Financial" and the Ramp
            logo are trademarks of the company.
            <RyuBreak />
            The Ramp Visa Commercial Card and the Ramp Visa Corporate Card are issued by Sutton Bank and
            Celtic Bank (Members FDIC), respectively. Please visit our{' '}
            <RyuLink href='https://ramp.com/legal/platform-agreement' externalLinkIcon={false}>
              Terms of Service
            </RyuLink>{' '}
            for more details.
          </RyuText>
        </RyuPad>
      </RegularPageContainer>
    </BlogFooterRoot>
  )
})
