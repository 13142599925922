import { RyuLink } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

export const MarkdownAnchorAdapter: FunctionComponent<any> = ({ href, ...props }) => {
  if (href.startsWith('/')) {
    return <RyuLink color='hushed' as={Link} asProps={{ to: href }} {...props} />
  }

  return <RyuLink color='hushed' href={href} {...props} />
}
