import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

type Props = {
  src: string
}

const YouTubeVideoEmbedRoot = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
`

const YouTubeVideoEmbedFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const YouTubeVideoEmbed: FunctionComponent<Props> = ({ src }) => (
  <YouTubeVideoEmbedRoot>
    <YouTubeVideoEmbedFrame
      src={src}
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    />
  </YouTubeVideoEmbedRoot>
)
