import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
blockquote.twitter-tweet {
  overflow: hidden;
  color: #1c2022;
  background-color: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  width: 500px;
  max-width: 100%;
  min-width: 220px;
  padding: 1.25rem 1.25rem .725rem 1.25rem;

  &:before {
    content: none;
  }

  p {
    white-space: pre-wrap;
    font: 16px/1.4 Helvetica, Roboto, 'Segoe UI', Calibri, f-core;
  }

  a {
    color #2b7bb9;
  }

  a:visited {
    color: #2b7bb9;
  }
}
`
