import { withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet-async'

// @ts-expect-error Importing image
import twitterHero from '~/src/images/ramp_engineering.png'
import { BlogPost } from '~/src/types'
import { ORIGIN, RAMP_TWITTER } from '~/src/utils/metadata'

type Props = {
  blogPost: BlogPost
}

export const BlogPostMetadata: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost }) => {
    const {
      author: { name, website, twitter = RAMP_TWITTER },
      date,
      path,
      title,
      description,
      thumbnail,
    } = blogPost

    return (
      <Helmet>
        <link rel='canonical' href={ORIGIN + path} />

        <meta name='author' itemProp='creator' content={`${name} <${website}>`} />

        <meta
          name='description'
          property='og:description'
          itemProp='description'
          content={description}
        />

        <meta property='og:url' itemProp='url' content={ORIGIN + path} />
        <meta property='og:image' itemProp='images' content={ORIGIN + (thumbnail ?? twitterHero)} />

        <meta name='twitter:title' itemProp='name' property='og:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:site' content={RAMP_TWITTER} />
        <meta name='twitter:creator' content={twitter} />

        <meta name='twitter:image:src' content={ORIGIN + (thumbnail ?? twitterHero)} />

        <meta name='dateCreated' content={date.toISOString()} />
        <meta name='dateModified' content={date.toISOString()} />
        <meta name='datePublished' content={date.toISOString()} />
      </Helmet>
    )
  },
  { inline: true }
)
