import { RyuText } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'

import { TitledContent } from '~/src/components/TitledContent'

export const NotFoundRoute: FunctionComponent = () => {
  return (
    <TitledContent title='Page Not Found'>
      <RyuText color='hushed'>Page not found.</RyuText>
    </TitledContent>
  )
}
