import { RyuFlex, RyuPad, RyuText, withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { RegularPageContainer } from '~/src/components/RegularPageContainer'

import rampLogoSvg from './ramp-logo.svg'

const BlogHeaderRoot = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(31, 31, 31, 0.05);
  backdrop-filter: blur(25px);
  z-index: 99999;
`

const NavLink = styled.a`
  text-decoration: none !important;
  padding: 0.6rem 1.2rem;
  margin-right: 1rem;
  transition: all 0.2s;
  border-radius: 4px;

  &:hover {
    background-color: rgba(31, 31, 31, 0.05);
  }
`

const ButtonLink = styled.a`
  border-radius: 4px;
  background-color: rgba(31, 31, 31);
  padding: 0.6rem 1rem;
  transition: all 0.2s;

  color: #fff !important;
  text-decoration: none !important;

  &:hover {
    background-color: rgba(31, 31, 31, 0.734);
  }
`

const HideOnMobile = styled.div`
  display: none;

  @media (min-width: 512px) {
    display: flex;
    align-items: center;
  }
`

export const BlogHeader: FunctionComponent = withRyuErrorBoundary(() => {
  return (
    <BlogHeaderRoot>
      <RegularPageContainer>
        <RyuPad size='l' sizeVertical='l'>
          <RyuText color='hushed'>
            <RyuFlex alignItems='center' justifyContent='spaceBetween'>
              <Link to='/' style={{ textDecoration: 'none' }}>
                <RyuFlex alignItems='center'>
                  <img src={rampLogoSvg} alt='Ramp' />
                  <HideOnMobile>
                    <div
                      style={{
                        height: '2rem',
                        width: '1px',
                        background: '#00000020',
                        margin: '0 1rem',
                      }}
                    />
                    <RyuText size='m'>Engineering</RyuText>
                  </HideOnMobile>
                </RyuFlex>
              </Link>
              <RyuFlex alignItems='center'>
                <NavLink href='https://ramp.com' target='_blank' rel='noreferrer'>
                  <RyuText size='s' color='primary'>
                    <span style={{ fontWeight: 500 }}>Home</span>
                  </RyuText>
                </NavLink>
                <ButtonLink href='https://ramp.com/careers' target='_blank' rel='noreferrer'>
                  <RyuText size='s'>
                    <span style={{ fontWeight: 500 }}>We're hiring!</span>
                  </RyuText>
                </ButtonLink>
              </RyuFlex>
            </RyuFlex>
          </RyuText>
        </RyuPad>
      </RegularPageContainer>
    </BlogHeaderRoot>
  )
})
