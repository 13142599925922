import '~/src/styles/fonts.css'

import { setupRyu } from '@ramp/ryu'
import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import { StyleSheetManager } from 'styled-components'

import { BlogRoot } from './components/BlogRoot'

const appRootElement = document.getElementById('ramp-engineering')

if (!appRootElement) {
  throw new Error("Couldn't find `#ramp-engineering`")
}

setupRyu({ appRootElement })

const app = (
  <StrictMode>
    {/*
      Without `disableCSSOMInjection`, the pre-rendered pages aren't styled until JavaScript kicks in.

      Ideally, we would only set `disableCSSOMInjection` when pre-rendering with `react-spa-prerender`,
      but we don't care enough to spend time on it. It's just a blog… Feel free to take though.

      We also don't rely on hydration, even when the page is pre-rendered and even when
      CSSOM injection is turned off, as something else is borked there.
      Seems to be related to the magical internals in `RyuFlex` (see `flatMapReactChildren` in Ryu source).

      Context: https://github.com/prerender/prerender/issues/609#issuecomment-573614786
      Related: https://styled-components.com/docs/api#stylesheetmanager
      Related: https://github.com/sumatokado/ramp-engineering-blog/pull/3
    */}
    <StyleSheetManager disableCSSOMInjection={true}>
      <BlogRoot />
    </StyleSheetManager>
  </StrictMode>
)

render(app, appRootElement)
