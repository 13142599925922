
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuFlex } from '@ramp/ryu'
import aiPrinciplesThumbnail from './ai-principles-thumbnail.png'
export const meta = {
  date: '2023-07-11T17:00:00.000Z',
  title: 'How Ramp builds customer-first AI',
  description: 'Principles for developing AI models that consistently deliver meaningful outcomes',
  thumbnail: aiPrinciplesThumbnail,
  author: {
    name: 'Yunyu Lin',
    website: 'https://twitter.com/yunyu_l',
    twitter: '@yunyu_l',
    position: 'Head of Applied AI'
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Artificial intelligence is transforming how we work and build software. Companies like Ramp, Amazon, and Google have been using AI for years to provide recommendations, extract insights, and combat fraud. Since our founding, we’ve `}<a parentName="p" {...{
        "href": "https://ramp.com/blog/announcing-ramp-intelligence"
      }}>{`built AI tools`}</a>{` to save customers over $400 million to date.`}</p>
    <p>{`However, most AI solutions today are superficial chat experiences that lack real customer value. We think these are prime examples of "AI washing"—using AI for the hype rather than the impact.`}</p>
    <p>{`At Ramp, we follow a few principles to ensure our models consistently deliver meaningful outcomes—and more importantly, save customers time and money.`}</p>
    <h1>{`Focus on outcomes, not interfaces`}</h1>
    <p>{`Many AI implementations are a thin chat layer on top of a product that customers have to go out of their way to use. But AI should be more than a flashy chatbot interface—it should be embedded in your workflows to actually get things done.`}</p>
    <iframe src='//fast.wistia.net/embed/iframe/dlupd7t6ut?videoFoam=true&autoPlay=true&controlsVisibleOnLoad=false&endVideoBehavior=loop' frameBorder={0} scrolling='no' className='wistia_embed' name='wistia_embed' allowFullScreen='' mozallowfullscreen='' webkitallowfullscreen='' oallowfullscreen='' msallowfullscreen='' style={{
      margin: '32px auto',
      display: 'block',
      aspectRatio: '1',
      width: '100%',
      maxWidth: '480px'
    }} />
    <p>{`For example, when you’re `}<a parentName="p" {...{
        "href": "https://ramp.com/vendor-management"
      }}>{`managing contracts`}</a>{` on Ramp, you don’t want to ask a chatbot question after question—you want the key details extracted and analyzed for you. Having AI pull out and highlight the most important terms from an automatically imported contract is far more useful than a chatbot that requires manually uploading documents and posing individual, one-off questions. AI should work for people, not the other way around.`}</p>
    <h1>{`Separate general knowledge and private data`}</h1>
    <p>{`Large language models are good at common-sense reasoning because they have been trained on huge datasets to learn relationships between concepts. However, for a fintech platform like Ramp, models also need to understand concepts and sensitive customer data not found in these general datasets. Unlike companies that train models on private data end-to-end, we safeguard data by splitting models into two categories:`}</p>
    <ol>
      <li parentName="ol">{`General models trained on aggregated and masked customer data to handle common tasks. These shared models learn patterns across customers by understanding the `}<em parentName="li">{`structure`}</em>{` of the data rather than the `}<em parentName="li">{`actual contents`}</em>{`.`}</li>
      <li parentName="ol">{`Sensitive models that can temporarily use private customer data without storing it, using a technique called in-context learning.`}</li>
    </ol>
    <p>{`For example, our contract pricing benchmarks use a shared model to extract line items and terms from vendor documents. We use a general model for this purpose to ensure that it performs well across a wide variety of contracts. If customers opt in to share pricing data derived from their contracts, we then use in-context learning to benchmark their pricing against pricing data shared by similar customers.`}</p>
    <iframe src='//fast.wistia.net/embed/iframe/08191nvhlf?videoFoam=true&autoPlay=true&controlsVisibleOnLoad=false&endVideoBehavior=loop' frameBorder={0} scrolling='no' className='wistia_embed' name='wistia_embed' allowFullScreen='' mozallowfullscreen='' webkitallowfullscreen='' oallowfullscreen='' msallowfullscreen='' width={480} height={480} style={{
      margin: '32px auto',
      display: 'block',
      aspectRatio: '1',
      width: '100%',
      maxWidth: '480px'
    }} />
    <p>{`By separating our models into general and private domains, we enable powerful insights while upholding data privacy. Customers then have the choice to share their contract data to improve Ramp’s models, but `}<em parentName="p">{`only if they explicitly opt in`}</em>{`.`}</p>
    <p>{`This framework ensures that no customer's contract information is used without their permission. It allows us to build models that understand both general knowledge and customer specifics, without risking accidental exposure of private data.`}</p>
    <h1>{`Explainability isn’t enough—offer users control`}</h1>
    <p>{`The best AI is useless without trust from users. Explainability, which attempts to trace how models make their decisions, seeks to build trust. But explanations aren’t always helpful or relevant—it’s more important that models improve and are responsive to user feedback.`}</p>
    <p>{`For example, if Ramp’s spend intelligence model incorrectly codes a purchase to the wrong accounting category, a lengthy explanation of the model logic isn’t particularly useful. We simply allow customers to provide feedback so the model learns for the future. Focusing on control and continuous improvement is more meaningful than attempting to explain every AI decision.`}</p>
    <iframe src='//fast.wistia.net/embed/iframe/03a5645trg?videoFoam=true&autoPlay=true&controlsVisibleOnLoad=false&endVideoBehavior=loop' frameBorder={0} scrolling='no' className='wistia_embed' name='wistia_embed' allowFullScreen='' mozallowfullscreen='' webkitallowfullscreen='' oallowfullscreen='' msallowfullscreen='' width={480} height={480} style={{
      margin: '32px auto',
      display: 'block',
      aspectRatio: '1',
      width: '100%',
      maxWidth: '480px'
    }} />
    <h1>{`Build guardrails, not censorship`}</h1>
    <p>{`As AI systems gain wider adoption, the risks posed by inappropriate, nonsensical, or offensive outputs also increase. Rather than censoring AI model outputs, we design models to make unwanted outputs impossible in the first place. In contrast, models like ChatGPT take natural language inputs and apply censorship filters to the outputs, blocking offensive or nonsensical responses. The problem is that censorship can be incomplete—it's difficult for filters to anticipate every possible unwanted output, especially as language models become more advanced.`}</p>
    <p>{`Ramp’s `}<a parentName="p" {...{
        "href": "https://ramp.com/intelligence"
      }}>{`Copilot AI`}</a>{` is great at processing natural language to generate search queries from customer data. But instead of having the model directly respond using plain text (which could contain unwanted or harmful content), we show the results of those queries using predefined blocks of interactive, structured data. This allows Copilot to interpret the nuances of natural language while ensuring safe, controlled outputs.`}</p>
    <iframe src='//fast.wistia.net/embed/iframe/xae3fh2zvn?videoFoam=true&autoPlay=true&controlsVisibleOnLoad=false&endVideoBehavior=loop' frameBorder={0} scrolling='no' className='wistia_embed' name='wistia_embed' allowFullScreen='' mozallowfullscreen='' webkitallowfullscreen='' oallowfullscreen='' msallowfullscreen='' width={480} height={480} style={{
      margin: '32px auto',
      display: 'block',
      aspectRatio: '1',
      width: '100%',
      maxWidth: '480px'
    }} />
    <p>{`This approach to "guardrails, not censorship" allows us to leverage powerful language models with confidence that we can build and maintain safe constraints on their operation. For example, we use techniques like `}<a parentName="p" {...{
        "href": "https://github.com/1rgs/jsonformer"
      }}>{`Jsonformer`}</a>{` (pioneered at Ramp) to convert unstructured text into formats that can be safely interpreted and used. Overall, designing safety from the start leads to more robust and trustworthy AI than retroactively censoring model outputs.`}</p>
    <h1>{`Creating better AI`}</h1>
    <p>{`AI will keep advancing, but it must be grounded in customer needs to provide real value. For Ramp, we’re focused on developing AI that gets work done, uses data responsibly, builds trust through improvement, and is designed safely from the start. By following these principles, we'll keep building AI that serves our customers rather than hype.`}</p>
    <p>{`If you like our approach to building products, we’d love to connect. `}<a parentName="p" {...{
        "href": "https://ramp.com/careers"
      }}>{`Check out our careers page`}</a>{`.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;