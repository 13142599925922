import { RyuMarkdownProvider, RyuProvider } from '@ramp/ryu'
import { createBrowserHistory } from 'history'
import React, { FunctionComponent } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Router } from 'react-router-dom'

import { MarkdownAnchorAdapter } from '~/src/components/MarkdownAnchorAdapter'
import { MdxAdapter } from '~/src/components/MdxAdapter'
import { Routes } from '~/src/routes'

const history = createBrowserHistory()

export const BlogRoot: FunctionComponent = () => {
  return (
    <HelmetProvider>
      <Router history={history}>
        <RyuProvider>
          <RyuMarkdownProvider components={{ a: MarkdownAnchorAdapter }}>
            <MdxAdapter>
              <Routes />
            </MdxAdapter>
          </RyuMarkdownProvider>
        </RyuProvider>
      </Router>
    </HelmetProvider>
  )
}
