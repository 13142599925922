import { RyuBreak, withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'

import { BlogPostAttribution } from '~/src/components/BlogPostAttribution'
import { BlogPostCTA } from '~/src/components/BlogPostCTA'
import { BlogPostMetadata } from '~/src/components/BlogPostMetadata'
import { TitledContent } from '~/src/components/TitledContent'
import { BlogPost } from '~/src/types'

type Props = {
  blogPost: BlogPost
}

const BlogPostRoute: FunctionComponent<Props> = withRyuErrorBoundary(({ blogPost }) => {
  const { title, MdxContent } = blogPost

  return (
    <TitledContent title={title}>
      <BlogPostMetadata blogPost={blogPost} />
      <BlogPostAttribution blogPost={blogPost} />

      <RyuBreak />

      <MdxContent />

      <RyuBreak size='xl' />

      <BlogPostCTA />
    </TitledContent>
  )
})

export function withBlogPost(blogPost: BlogPost) {
  const BlogPost = () => <BlogPostRoute blogPost={blogPost} />
  return BlogPost
}
