import styled from 'styled-components'
import React, { useRef, useState, useEffect } from 'react'
import { motion, useAnimation, AnimationControls } from 'framer-motion'

const uniqueEmojis = ['📎', '💻', '📚', '✈️', '🚗', '🏨', '🍔', '🏝️']
const legend = {
  '📎': 'Office supplies',
  '💻': 'Technology',
  '📚': 'Education',
  '✈️': 'Travel',
  '🚗': 'Transportation',
  '🏨': 'Accommodation',
  '🍔': 'Food',
  '🏝️': 'Vacation',
}

const emojis = uniqueEmojis.flatMap((emoji) => Array(10).fill(emoji))

const containerHeight = 500

interface Position {
  x: number
  y: number
}

interface Positions {
  scatteredPositions: Position[]
  clusteredPositions: Position[]
}

const generatePositions = (width: number, height: number): Positions => {
  const scatteredPositions = Array.from({ length: emojis.length }, () => ({
    x: Math.random() * (width - 40), // Adjust for emoji size
    y: Math.random() * (height - 40), // Adjust for emoji size
  }))

  const clusteredPositions = Array.from({ length: emojis.length }, (_, i) => {
    const group = Math.floor(i / 10) // Divide emojis into groups of 10
    const angle = ((i % 10) / 10) * Math.PI * 2
    const radius = 20 + Math.random() * 30 // Random radius between 20 and 50
    const centerX = scatteredPositions[group * 10].x // Center of the cluster based on the first emoji in the group
    const centerY = scatteredPositions[group * 10].y // Center of the cluster based on the first emoji in the group
    return {
      x: Math.min(Math.max(centerX + radius * Math.cos(angle), 0), width - 40),
      y: Math.min(Math.max(centerY + radius * Math.sin(angle), 0), height - 40),
    }
  })

  return { scatteredPositions, clusteredPositions }
}

const EmbeddingsAnimation: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const controls: AnimationControls = useAnimation()
  const [positions, setPositions] = useState<Positions>({
    scatteredPositions: [],
    clusteredPositions: [],
  })
  const hasStarted = useRef<boolean>(false)
  const [label, setLabel] = useState<string>('❌ Not tuned')

  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth
      const { scatteredPositions, clusteredPositions } = generatePositions(width, containerHeight)
      setPositions({ scatteredPositions, clusteredPositions })
    }
  }, [])

  useEffect(() => {
    const sequence = async () => {
      const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

      while (true) {
        const width = containerRef.current!.offsetWidth
        const { scatteredPositions, clusteredPositions } = generatePositions(width, containerHeight)
        setPositions({ scatteredPositions, clusteredPositions })

        setLabel('❌ Not tuned')
        await controls.start((i) => ({
          x: scatteredPositions[i].x,
          y: scatteredPositions[i].y,
          transition: { type: 'spring', stiffness: 150, damping: 20, duration: 2 },
        }))
        await delay(2500) // Moved setTimeout to a separate function

        setLabel('✅ Fine-tuned')
        await controls.start((i) => ({
          x: clusteredPositions[i].x,
          y: clusteredPositions[i].y,
          transition: { type: 'spring', stiffness: 150, damping: 20, duration: 2 },
        }))
        await delay(2500) // Moved setTimeout to a separate function
      }
    }

    if (positions.scatteredPositions.length > 0 && !hasStarted.current) {
      hasStarted.current = true
      sequence()
    }
  }, [controls, positions])

  return (
    <div>
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          width: '100%',
          height: '500px',
          backgroundSize: '40px 40px', // Less dense grid
          backgroundColor: 'white',
          border: '1px dashed lightgray',
        }}
      >
        {[...Array(Math.ceil(500 / 40)).keys()].map((row) => (
          <div
            key={`row-${row}`}
            style={{
              position: 'absolute',
              top: `${row * 40 - 1}px`,
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: 'transparent',
              borderTop: '1px dashed lightgray',
            }}
          />
        ))}
        {[...Array(Math.ceil((containerRef.current?.offsetWidth ?? 0) / 40)).keys()].map((col) => (
          <div
            key={`col-${col}`}
            style={{
              position: 'absolute',
              top: 0,
              left: `${col * 40 - 1}px`,
              width: '1px',
              height: '100%',
              backgroundColor: 'transparent',
              borderLeft: '1px dashed lightgray',
            }}
          />
        ))}
        {emojis.map((emoji, i) => (
          <motion.div
            key={i}
            custom={i}
            animate={controls}
            style={{
              position: 'absolute',
              fontSize: '2rem',
            }}
          >
            {emoji}
          </motion.div>
        ))}

        <Label
          animate={{ opacity: [0, 1, 1, 1], transition: { duration: 1, times: [0, 0.2, 0.8, 1] } }} // Adjusted opacity animation
          key={label}
        >
          {label}
        </Label>
      </div>
      <div style={{ marginTop: '10px' }}>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', listStyleType: 'none' }}
        >
          {Object.entries(legend).map(([emoji, label]) => (
            <LegendItem key={emoji}>
              <div style={{ fontSize: '1.2rem', marginRight: '10px' }}>{emoji}</div>
              {label}
            </LegendItem>
          ))}
        </div>
      </div>
    </div>
  )
}

const Label = styled(motion.div)`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.5rem;
  background: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 12px;
  font-size: 1rem;
`

export default EmbeddingsAnimation
