
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { RyuImage, RyuFlex } from '@ramp/ryu'
import aiPrinciplesThumbnail from './ai-principles-thumbnail.png'
import EmbeddingsAnimation from 'src/components/BlogAssets/transaction-embeddings/embeddings-animation'
import image1Table from './image1-table.png'
import image2Chart from './image2-chart.png'
import image3Chart from './image3-chart.png'
import image4Compare from './image4-compare.png'
import image5Example from './image5-example.png'
import image6Screenshot1 from './image6-screenshot1.png'
import image7Screenshot2 from './image7-screenshot2.png'
import image8Super from './image8-super.png'
export const meta = {
  date: '2024-08-13T17:00:00.000Z',
  title: 'Improving Retrieval on Ramp with Transaction Embeddings',
  description: 'How we used triplet loss and embeddings to improve accounting on Ramp.',
  author: {
    name: 'Calix Huang and Anton Biryukov'
  }
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Millions of transactions take place every month on Ramp. Our mission is to automate finance tasks for our customers. One example of this is accounting coding.`}</p>
    <p>{`Accounting coding is the process of categorizing transactions based on each business' accounting system, also known as Enterprise Resource Planning (ERP). With Ramp's accounting integrations, we are able to sync and understand each business' general ledger (GL) categories, however employees are still responsible for coding their own transactions and expenses. Given that the majority of employees are not finance and accounting experts, accounting coding can be confusing and error prone for them. Employees have to search through hundreds of different accounting codes, with little clarity on what they exactly represent. As a result, any human error during this process results in additional work for the finance teams when closing the books.`}</p>
    <RyuImage src={image1Table} mdxType="RyuImage" />
    <p style={{
      textAlign: 'center',
      fontSize: '0.8rem',
      color: 'grey'
    }}>
  Each stakeholder’s context and knowledge in the accounting coding process.
    </p>
    <p>{`Developing a relational understanding between similar transactions has big implications for improving the Ramp experience, for both employees and their finance teams. Some of those implications are:`}</p>
    <ul>
      <li parentName="ul">{`Saving time on the accounting coding process for employees, finance teams, and accountants`}</li>
      <li parentName="ul">{`Providing recommendations and insights on business spend and transactions`}</li>
      <li parentName="ul">{`Segmenting businesses by their spending patterns`}</li>
    </ul>
    <p>{`Therefore, we started to look for ways to represent transactional data so that we could group similar transactions together and semantically search across those transactions. The goal was to generate transaction embeddings that could cluster similar transactions by their respective GL category. This way, we could predict a GL category for a new transaction by matching accounting codes attached to similar transactions.`}</p>
    <h1>{`Approach`}</h1>
    <p>{`We decided to train our model using a triplet loss function, which we'll get to in a bit. We represented transactions as documents with attached labels (chart of account codes). The dataset was then filled with triplet samples, each made up of 3 documents with their associated GL categories. Using triplet loss, the model can learn differences between similar and dissimilar documents, and can cluster transactions by their respective GL categories.`}</p>
    <EmbeddingsAnimation mdxType="EmbeddingsAnimation" />
    <h1>{`Model architecture`}</h1>
    <p>{`Though there are many off-the-shelf embedding models and various approaches to training custom embedding models, we chose an architecture that could easily scale with the large variety of transactions on Ramp. We also needed searches across these transactions to be fast to serve downstream applications, so we chose a smaller embedding size for quick retrieval.`}</p>
    <RyuImage src={image2Chart} mdxType="RyuImage" />
    <p style={{
      textAlign: 'center',
      fontSize: '0.8rem',
      color: 'grey'
    }}>
  For a given transaction (e.g. a new laptop), we want to find merchants selling electronics among neighbors in an embedding space.
    </p>
    <p>{`To train our model we used `}<a parentName="p" {...{
        "href": "https://sbert.net/"
      }}><inlineCode parentName="a">{`sentence_transformers`}</inlineCode></a>{`, a popular Python library to train embedding models and compute dense vector text representations. Starting with a pre-trained encoder as a base model, we fine-tuned it on Ramp’s transactional data using triplet loss. We then use cosine similarity as a distance metric in the learnt latent space to quantify the similarity between two transactions.`}</p>
    <h3>{`Triplet loss`}</h3>
    <p>{`Triplet loss is a popular loss function for supervised similarity and metric learning. It evaluates samples in triplets, consisting of an anchor, a positive sample, and a negative sample. The anchor represents a document with a label, the positive sample represents a document that has the same label as the anchor, and the negative sample represents a document that has a different label from the anchor. For instance, an anchor document and positive document can be labeled with GL account “7000 Personal Expenses”, while the negative document could be labeled with “6300 Utility Expenses”. Triplet loss teaches an embedding model to recognize the similarity or difference between the documents. In other words, it learns to “push” negative samples away from the anchor, and pull positive samples towards the anchor at the same time. The distance between the anchor and the positive/negative samples is driven by a hyperparameter denominated `}<inlineCode parentName="p">{`margin`}</inlineCode>{`.`}</p>
    <RyuImage src={image3Chart} mdxType="RyuImage" />
    <p style={{
      textAlign: 'center',
      fontSize: '0.8rem',
      color: 'grey'
    }}>
  Visualization of anchor, positive, and negative samples in triplet loss.
    </p>
    <p>{`As a comparison, `}<strong parentName="p">{`contrastive loss`}</strong>{` is another popular loss function that can model similarities of inputs. Contrastive loss samples data in pairs, each associated with a positive or negative label, and aims to either place both samples either close together or distanced by a given margin. The main difference between triplet loss and contrastive loss is the use of the `}<inlineCode parentName="p">{`margin`}</inlineCode>{` hyperparameter. Since contrastive loss does not consider marginal distance when evaluating positive samples, it does not incur any changes when evaluating a positive cluster of samples.`}</p>
    <p>{`As illustrated in the figure below, triplet loss creates more opportunity for the vector space to cluster into different groups, whereas contrastive loss mainly succeeds at separating positive and negative samples from each other. Triplet loss maintains more tolerance towards intra-class variance by ensuring margins between both positive and negative samples, while contrastive loss does not. This can result in contrastive loss hitting a local minima faster, while triplet loss can still work on reorganizing space.`}</p>
    <RyuImage src={image4Compare} mdxType="RyuImage" />
    <p style={{
      textAlign: 'center',
      fontSize: '0.8rem',
      color: 'grey'
    }}>
  Embeddings from contrastive loss (left) vs. triplet loss (right). <a href="https://towardsdatascience.com/triplet-loss-advanced-intro-49a07b7d8905">Image credits</a>.
    </p>
    <p>{`A difficult aspect of implementing triplet loss is mining good triplet samples. Specifically, if we mine triplets at random, the model quickly learns the difference between the “Travel” and “Repairs & Maintenance” GL categories, and the loss function reaches a plateau. The nuance between GL categories like “Travel: Sales” and “Travel: Engineering” is important to accountants, so we need to be able to tell them apart, and this requires highly-informed sampling strategies.`}</p>
    <p>{`Triplet mining is a hot topic with many methods worth considering. In our case and for the sake of code simplicity, we experimented with modifications to the loss function readily available in `}<inlineCode parentName="p">{`sentence-transformers`}</inlineCode>{`. We found the `}<inlineCode parentName="p">{`BatchSemiHardTripletLoss`}</inlineCode>{` function to produce best results, while sampling tricky triplets from a corpus of contextual transactions on the fly. Using large batch sizes and the loss function allowed us to keep our training data relevant, making sure we were consistently feeding in informative triplet samples during training.`}</p>
    <RyuImage src={image8Super} mdxType="RyuImage" />
    <p style={{
      textAlign: 'center',
      fontSize: '0.8rem',
      color: 'grey'
    }}>
  Examples of tricky cases in triplet loss training, highlighting hard positives on the left (similar items that appear to be different) and hard negatives on the right (dissimilar items that appear deceptively similar). These examples challenge the model’s ability to learn effective embeddings.
    </p>
    <h3>{`Representing transactions as embeddings`}</h3>
    <p>{`Before training, we had to understand how we could represent transactions as strings, to then convert into embeddings. We enriched transactions into contextual transactions, which combined all of the features of transactions that were most relevant to its accounting categorization. These features included (but were not limited to):`}</p>
    <ul>
      <li parentName="ul">{`Merchant name`}</li>
      <li parentName="ul">{`Merchant category name (MCC)`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Represents the general “category” of the transaction`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`Department name`}</li>
      <li parentName="ul">{`Location name`}</li>
      <li parentName="ul">{`Amount`}</li>
      <li parentName="ul">{`Memo`}</li>
      <li parentName="ul">{`Spend program name`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Represents the specific spend limit allocated for this transaction`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`Trip name`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Represents the trip they were on, if the transaction took place on travel`}</em></li>
        </ul>
      </li>
    </ul>
    <RyuImage src={image5Example} mdxType="RyuImage" />
    <p>{`To keep these embeddings relevant for external transactions and external use cases (e.g. coding external, non-Ramp transactions synced from an ERP), we had to ensure the embeddings were generalizable. This meant enriching transactions with just the above information, keeping them relevant and useful for Ramp businesses of any size or in any industry.`}</p>
    <p>{`Ultimately, the labels for these embeddings were the corresponding GL categories, which together represented the respective accounting codes that were assigned to each transaction. Using these features and labels, we were able to represent transactions as stringified prompts to input to a custom embeddings model and match querying transactions to similar, already-coded transactions to reference.`}</p>
    <h1>{`Applications`}</h1>
    <p>{`We surface the predictions using the trained embeddings across multiple verticals and platforms, and use them both directly and as an input for downstream tasks. Many of these downstream tasks include match-based predictions or suggestion-based predictions to help streamline user interactions.`}</p>
    <p>{`For example, whenever a user on Ramp needs to provide a code for a transaction, we now surface our GL coding suggestions in a dropdown.`}</p>
    <RyuImage src={image6Screenshot1} mdxType="RyuImage" />
    <p>{`If the prediction confidence for a GL category is high, we can make a more overt recommendation to the user, like using that as the default value for a given transaction.`}</p>
    <RyuImage src={image7Screenshot2} mdxType="RyuImage" />
    <p>{`Outside of accounting, we also use transaction embeddings for analytics, giving our Growth and Data analysts the ability to quantify similarities among businesses, based on their spending and coding patterns. We’re also able to use similarities among transactions to synthesize relevant transactions as context for other LLM-enabled features, such as our recently-released `}<a parentName="p" {...{
        "href": "https://support.ramp.com/hc/en-us/articles/360042588454-Submitting-receipts-and-memos-for-your-Ramp-card#h_01HRAPJPWQJ7D8VK49SZZ2BTPV"
      }}>{`suggested memos`}</a>{` feature.`}</p>
    <h1>{`Final thoughts`}</h1>
    <p>{`Ramp is in the business of saving customers both time and money. One of the ways to achieve that is through leveraging the right tools with the right data, at the right time. Our continued investment in engineering efforts to develop our own ML infrastructure and purpose-fit models allows us to improve automation at scale for our customers, while keeping their data private and the predictions — personalized.`}</p>
    <p>{`It’s day 1975 at Ramp and we still have a long road ahead of us; if you're interested in the kind of opportunities and challenges we discussed in this article, We’d love to hear from you and maybe have you `}<a parentName="p" {...{
        "href": "https://ramp.com/careers"
      }}>{`join us`}</a>{` on this journey.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;