import { RyuBreak, RyuFlex, RyuLink, RyuPad, RyuText, RyuTooltip, withRyuErrorBoundary } from '@ramp/ryu'
import React, { FunctionComponent } from 'react'

import { BlogPost } from '~/src/types'

type Props = {
  blogPost: BlogPost
  disableLink?: boolean
}

export const BlogPostAttribution: FunctionComponent<Props> = withRyuErrorBoundary(
  ({ blogPost, disableLink }) => {
    const {
      author: { name, website, position, bio, photo },
      date,
    } = blogPost

    return (
      <RyuText color='hushed' size='s'>
        <RyuFlex marginSize='xs'>
          <RyuTooltip
            paddingSize='none'
            content={
              (position || bio || photo) && (
                <RyuFlex marginSize='none' alignItems='flexStart' justifyContent='flexStart'>
                  {photo && (
                    <img
                      src={photo}
                      alt=''
                      style={{ display: 'block', width: '5rem', maxWidth: '5rem' }}
                    />
                  )}

                  {(position || bio) && (
                    <RyuPad size='xs'>
                      {position}

                      {position && bio && <RyuBreak />}

                      {bio}
                    </RyuPad>
                  )}
                </RyuFlex>
              )
            }
          >
            {disableLink ? (
              <span style={{ textDecoration: 'underline' }}>{name}</span>
            ) : (
              <RyuLink href={website} externalLinkIcon={false}>
                {name}
              </RyuLink>
            )}
          </RyuTooltip>

          {'–'}

          {date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
        </RyuFlex>
      </RyuText>
    )
  },
  { inline: true }
)
