import { RyuBreak, RyuPad, withRyuErrorBoundary } from '@ramp/ryu'
import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { BlogFooter } from '~/src/components/BlogFooter'
import { BlogHeader } from '~/src/components/BlogHeader'
import { GlobalStyles } from '~/src/components/GlobalStyles'
import { RegularPageContainer } from '~/src/components/RegularPageContainer'
import { blogPosts } from '~/src/utils/blog'

import { BlogHomeRoute } from './BlogHomeRoute'
import { withBlogPost } from './BlogPostRoute'
import { NotFoundRoute } from './NotFoundRoute'

type Props = {}

const RoutesRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const RoutesContent = styled.div`
  flex-grow: 1;
`

export const Routes: FunctionComponent<Props> = () => {
  const blogPostRoutes = useMemo(
    () =>
      blogPosts.map((blogPost) => (
        <Route
          key={blogPost.path}
          exact={true}
          path={blogPost.path}
          component={withBlogPost(blogPost)}
        />
      )),
    []
  )

  return (
    <Fragment>
      <Helmet>
        <title>Ramp Engineering</title>
      </Helmet>

      <RoutesRoot>
        <BlogHeader />

        <RoutesContent>
          <RyuBreak />

          <RegularPageContainer>
            <RyuPad size='l' sizeVertical='l'>
              <Switch>
                <Route exact={true} path='/' component={withRyuErrorBoundary(BlogHomeRoute)} />

                {blogPostRoutes}

                <Route component={withRyuErrorBoundary(NotFoundRoute)} />
              </Switch>
            </RyuPad>
          </RegularPageContainer>

          <RyuBreak size='xxl' />
        </RoutesContent>

        <BlogFooter />
      </RoutesRoot>

      <GlobalStyles />
    </Fragment>
  )
}
