import { RyuBreak, RyuFlex, RyuHeading, RyuMarkdown, withRyuErrorBoundary } from '@ramp/ryu'
import React, { Fragment, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BlogPostAttribution } from '~/src/components/BlogPostAttribution'
import { BlogPost } from '~/src/types'

type Props = {
  blogPost: BlogPost
}

const BlogPostPreviewCardRoot = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(31, 31, 31, 0.05);

  &:last-child {
    border-bottom: none;
  }
`

export const BlogPostPreviewCard: FunctionComponent<Props> = withRyuErrorBoundary(({ blogPost }) => {
  const { path, title, description } = blogPost

  return (
    <BlogPostPreviewCardRoot to={path}>
      <RyuFlex direction='column' alignItems='flexStart' justifyContent='spaceBetween'>
        <RyuHeading size='s'>
          <span style={{ lineHeight: 1.3 }}>{title}</span>
        </RyuHeading>
        {description && (
          <Fragment>
            <RyuBreak size='m' />
            <RyuMarkdown color='hushed'>{description}</RyuMarkdown>
          </Fragment>
        )}
        <RyuBreak size='xs' />
        <BlogPostAttribution blogPost={blogPost} disableLink />
      </RyuFlex>
    </BlogPostPreviewCardRoot>
  )
})
